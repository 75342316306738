<template>
  <q-card>
    <q-card-section>
      <q-form
        ref="form"
        class="q-gutter-md"
      >
        <div class="row justify-between">
          <div class="col-12">
            <q-input
              v-model="payload.title"
              type="text"
              label="Title"
              dense
              lazy-rules
              :rules="[(val) => $rules('Title', val).required]"
            />
          </div>
          <div class="col-12">
            <q-input
              v-model="payload.url"
              type="text"
              label="Url"
              dense
              lazy-rules
              :rules="[(val) => $rules('Url', val).required]"
            />
          </div>
          <div class="col-12">
            <q-input
              v-model="payload.publisher"
              type="text"
              label="Publisher"
              dense
              lazy-rules
              :rules="[(val) => $rules('Publisher', val).required]"
            />
          </div>
        </div>
        <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-model="selectedDate.publishedAt"
              type="text"
              label="Published At"
              dense
              lazy-rules
              mask="####/##"
              readonly
              :rules="[(val) => $rules('Published At', val).required]"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="payload.publishedAt"
                      default-view="Months"
                      mask="YYYY-MM-DDTHH:mm:ssZ"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-5">
            <q-input
              v-model="selectedDate.expiredAt"
              type="text"
              label="Expired At"
              dense
              lazy-rules
              mask="####/##"
              readonly
              :disable="payload.isForever"
              :rules="[(val) => $rules('Expired At', val).required]"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="payload.expiredAt"
                      default-view="Months"
                      mask="YYYY-MM-DDTHH:mm:ssZ"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-5">
            <q-checkbox
              v-model="payload.isForever"
              label="Forever"
              dense
              size="sm"
            />
          </div>
        </div>
      </q-form>
    </q-card-section>

    <q-separator />

    <q-card-actions justify="space-around">
      <div class="col text-right">
        <q-btn-group>
          <q-btn outline size="sm" color="accent" @click="cancel">Cancel</q-btn>
          <q-btn outline size="sm" color="primary" @click="submit">{{ isEditMode ? 'Update' : 'Save' }}</q-btn>
        </q-btn-group>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, getCurrentInstance, onMounted, watch, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, ctx) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const payload = ref({
      degree: '',
      expiredAt: '',
      isForever: false,
      url: '',
      title: '',
      publisher: '',
      publishedAt: ''
    })

    const selectedDate = ref({
      publishedAt: '',
      expiredAt: ''
    })

    const isEditMode = computed(() => props.mode.toLowerCase() === 'edit')
    const degreeOptions = computed(() => store.getters?.ddDegrees)

    onMounted(() => {
      if (isEditMode) {
        selectedDate.value.publishedAt = props.data?.publishedAt
        selectedDate.value.expiredAt = props.data?.expiredAt
      }
    })

    watchEffect(() => {
      if (isEditMode) {
        payload.value = JSON.parse(JSON.stringify(props.data))
        payload.value.isForever = Boolean(props.data?.isForever)
      }
    })

    watch(payload.value, (n, o) => {
      selectedDate.value.publishedAt = payload.value.publishedAt
      selectedDate.value.expiredAt = payload.value.expiredAt
    })

    const cancel = () => {
      ctx.emit('onCancel')
    }

    const submit = async () => {
      const valid = await instance?.refs.form.validate()

      if (!valid) return

      let res
      if (isEditMode.value) {
        res = await self.$api.certificate.update(props.data?.id, payload.value)
      } else {
        if (!payload.value.expiredAt) {
          payload.value.expiredAt = payload.value.publishedAt
        }
        res = await self.$api.certificate.create(payload.value)
      }

      if (res?.success) {
        store.dispatch('setProfileFull')
      }

      ctx.emit('postSubmit')
    }

    return {
      payload,
      submit,
      cancel,
      isEditMode,
      degreeOptions,
      selectedDate
    }
  }
}
</script>
